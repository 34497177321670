import { ReturnState } from '../../types';
import { formatDate } from '../utils';
import { useTranslation } from 'react-i18next';

type InterpolationData = {
  createdAt: string;
  customerEmail: string;
};

export function useGeneralConfirmationMessage(
  submittedMessage: string,
  returnState: ReturnState | undefined,
  options: InterpolationData
) {
  const { t, i18n } = useTranslation();

  if (returnState === 'exists') {
    return t('confirmation_returnExistsMessage', { date: formatDate(options.createdAt, i18n.language) });
  }

  return t(submittedMessage, { customerEmail: options.customerEmail });
}
